import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import useAssetsApi, { Asset, mapAssetToRelativePath } from '../assets/useAssetApi';
import ResourceChartView from '../charts/scaffold/ResourceChartView';
import CategoriesIconDialogGrid from './CategoriesIconDialogGrid';

export interface CategoriesIconDialogViewProps {
  onSelect: (iconPath: string | undefined) => void;
  onClose: () => void;
}

export default function CategoriesIconDialogView({
  onSelect,
  onClose,
}: CategoriesIconDialogViewProps) {
  const api = useAssetsApi();
  const { t } = useTranslation();

  const [filter, setFilter] = useState<any>({});

  const handleFetch = useCallback(async () => {
    const files = await api.getFiles({ name: filter.search });
    return files;
  }, [api, filter]);

  const handleSelect = useCallback((asset: Asset) => {
    onSelect(mapAssetToRelativePath(asset));
  }, [onSelect]);

  const handleFilterChange = useCallback((event) => {
    setFilter({ search: event.target.value });
  }, []);

  const handleFilterChangeDebounced = useMemo(() => (
    debounce(handleFilterChange, 500)
  ), [handleFilterChange]);

  return (
    <>
      <Dialog
        open
        sx={{ '& .MuiDialog-paper': { height: '50vh' } }}
        maxWidth="lg"
        fullWidth
        onClose={() => { onClose(); }}
      >
        <DialogContent dividers sx={{ overflow: 'hidden' }}>
          <TextField
            label={t('categories.searchIcon')}
            onChange={handleFilterChangeDebounced}
            fullWidth
            variant="standard"
            autoFocus
          />
          <ResourceChartView
            Chart={CategoriesIconDialogGrid}
            ChartProps={{
              onSelect: handleSelect,
            }}
            fetch={handleFetch}
            filter={filter}
          />
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => { onClose(); }}>
            {t('button.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
